import PropTypes from 'prop-types'
import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import ProductCard from '../components/product-card'
import ProductFilter from '../components/product-filter'
import SEO from '../components/seo'
import Breadcrumbs from '../components/navigation/breadcrumbs'

const CategoryTemplate = ({ pageContext, data }) => {
	const { title, url } = pageContext
	const manufacturers = []

	if (data.allProduct) {
		data.allProduct.edges.map(({ node }) => {
			if (
				node.manufacturer &&
				manufacturers.findIndex(
					(manufacturer) =>
						manufacturer.slug.current === node.manufacturer.slug.current
				) === -1
			) {
				manufacturers.push(node.manufacturer)
			}
			return null
		})
	}

	return (
		<Layout>
			<SEO title={title} />
			{url && <Breadcrumbs url={url} />}
			<h1 className="uk-heading-line">
				<span>{title}</span>
			</h1>
			<div uk-filter="target: #products">
				<ProductFilter manufacturers={manufacturers} />

				<div id="products">
					{data.allProduct &&
						data.allProduct.edges.map(({ node }) => (
							<ProductCard key={node.slug.current} product={node} />
						))}
				</div>
			</div>
		</Layout>
	)
}

CategoryTemplate.propTypes = {
	pageContext: PropTypes.object,
}

export default CategoryTemplate

export const query = graphql`
	query CategoryProductsData($id: String!) {
		allProduct: allSanityProduct(
			filter: { categories: { elemMatch: { id: { eq: $id } } } }
			sort: { fields: _createdAt, order: DESC }
		) {
			edges {
				node {
					...ProductCardDataFragment
				}
			}
		}
	}
`
