import PropTypes from 'prop-types'
import React from 'react'
import { getSlug } from '../utils/url'

const ProductFilter = ({ manufacturers }) => {
	if (manufacturers.length < 2) {
		return null
	}

	const manufacturerElements = manufacturers.map((manufacturer) => (
		<li
			key={getSlug(manufacturer)}
			uk-filter-control={`[data-manufacturer='${getSlug(manufacturer)}']`}
		>
			<a href="#">{manufacturer.title}</a>
		</li>
	))

	return (
		<ul className="uk-subnav uk-subnav-pill uk-margin">
			<li className="uk-active" uk-filter-control="">
				<a href="#">Todos</a>
			</li>
			{manufacturerElements}
		</ul>
	)
}

ProductFilter.propTypes = {
	manufacturers: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default ProductFilter
